.council-rule-note{
    margin-bottom: 0px;
    font-size:15px;
}

.council-rule-button-label {
    margin-bottom:0px;
    font-size:15px;
    
}

.glowing-text {
    animation: textGlow 1s ease-in-out infinite alternate;
}

@keyframes textGlow {
    from {
      text-shadow:
       0 0 10px #fff, 
       0 0 20px #fff, 
       0 0 30px #fff;
    }
    
    to {
      text-shadow: 
        0 0 20px red, 
        0 0 30px red, 
        0 0 50px red,
        0 0 70px red, 
        0 0 90px red;
    }
  }

.list-container {
    margin-bottom:0px;
}

.list-item {
    margin-left: 2%;
}