.header_margin_Impervious {
    margin: 2px 5px;
    padding: 1px 2px;
}

.eol_result_margin {
    margin: 1px 2px;
    padding-right: 2px;
}

.imp_node_name_header {
    padding: 1px;
    width: 197px;
}

.imp_node_type_header {
    width: 162px;
}

.imp_node_area_header {
    width: 92px;
}

.imp_node_ttype_header {
    width: 212px;
}

.imp_node_tarea_header {
    width: 162px;
}

.imp_node_occupants_header {
    width: 92px;
}

.instaResult {
    width: 100px;
}

.instaResultSpacer {
    width: 35px;
}

.alert_set_impervious {
    padding: 0.2rem 1.25rem;
    margin-bottom: 0.3rem;
}

.table_line_impervious {
    border-top: 1px solid rgb(149, 186, 209);
}

.eol_tooltip {
    border-radius: 30px;
    text-align: center;
}

#bootstrap-dropdown-impervious {
    margin: 2px 5px;
    padding: 3px;
    width: 200px;
    height: 26px;
    font-size: 14px;
    text-align: left;
    position: sticky;
    overflow: hidden;
    z-index: 100;
}

.dropdown-menu {
    clear: both;
}

.dropdown-menu{
  transform: unset !important;
  inset: unset !important;
}

.eol-treatment-device-title {
    text-align: right;
    animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
      text-shadow:
       0 0 10px #fff, 
       0 0 20px #fff, 
       0 0 30px #fff;
    }
    
    to {
      text-shadow: 
        0 0 20px #f0530a, 
        0 0 30px #f0530a, 
        0 0 40px #f0530a;
    }
  }