body {
    background: #394d7b;
    font-size: 16px;
    text-align: justify;
}

.panOuterHome {
    display: flex;
    flex-direction: column;
    background: #bbcfed;
    border-radius: 10px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-top-right-radius: 100px;
    -o-border-top-radius: 200px;
    -ms-border-top-radius: 200px;
    -moz-border-top-radius: 200px;
    -webkit-border-top-radius: 200px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    /* margin: 1% 10%;
  padding: 20px 30px; */
    margin: 10px 0;
    padding: 10px 30px;
    position: relative;
}

.item {
    /* border: rgb(17, 11, 11) solid 2px; */
    padding: 10px;
    position: inherit;
    font-size: 15px;
}

.item h6 {
    font-weight: bold;
}


/* .flex-horizontal {
  display: flex;
  flex-direction: column;
  position: relative;
} */

@media screen and (max-width: 500px) {
    .flex-horizontal {
        flex-direction: column;
    }
}

@media screen and (min-width: 500px) {
    .flex-horizontal {
        display: flex;
        flex-direction: row;
        position: relative;
    }
}


/*  @media screen and (max-width: 125px) {
  .flex-vertical {
    flex-direction: column;
  }
}  */

@media screen and (min-width: 125px) {
    .flex-vertical {
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

.main-row {
    margin-top: 2%;
    margin-bottom: 2%;
}

.main-col {
    height: 100%;
    width: 32%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    overflow: scroll;
}

.panInnerHome {
    /* flex: 30%; */
    display: flex;
    flex-direction: column;
    background: #ffeaf6;
    border-radius: 10px;
    border-width: 3px;
    border-style: solid;
    border-color: #536380;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    position: relative;
    font-family: "Montserrat", sans-serif;
}

#email-tools {
    padding: 5%;
    min-width: 100%;
    min-height: 100%;
    font-weight: bolder;
}

.main-btn-row {
    display: flex;
}

.main-btn-col-1 {
    flex: 3;
    padding: 3px;
}

.main-btn-col-2 {
    flex: 1;
    padding: 3px;
}

.main-btn-col-1-item {
    min-width: 75%;
    min-height: 30%;
    margin-top: 2px;
}

.main-btn-col-2-item {
    min-width: 25%;
    min-height: 30%;
    margin-top: 2px;
}

#non-email-tools {
    padding: 2%;
    min-width: 100%;
    min-height: 47%;
    font-weight: bolder;
}

#brochure {
    padding: 1%;
    min-width: 100%;
    min-height: 45%;
    font-size: 15px;
    font-weight: bolder;
}

#news {
    padding: 3%;
    min-width: 100%;
    min-height: 100%;
    font-weight: bolder;
}

.inputEmail {
    margin-left: 0px;
}

.announcement {
    display: flex;
    flex-direction: column;
    flex: 80%;
    margin-left: 20px;
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    min-width: 300px;
    border-width: 3px;
    border-radius: 10px;
    min-height: 336px;
    max-height: 336px;
}

.btn-grad_home_blue {
    text-align: center;
    text-transform: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    display: block;
    background-image: linear-gradient(to right, #457fca 0%, #5691c8 51%, #457fca 100%);
}

.btn-grad_home_green {
    text-align: center;
    text-transform: capitalize;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    display: block;
    background-image: linear-gradient(to right, #62cf22 0%, #3cbd30 51%, #187c28 100%);
}

.btn-grad_home_orange {
    text-align: center;
    text-transform: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    display: block;
    background-image: linear-gradient(to right, #d8970b 0%, #be9409 51%, #ad8005 100%);
}

.btn-grad_home_purple {
    text-align: center;
    text-transform: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    display: block;
    background-image: linear-gradient(to right, #795794 0%, #795794 51%, #795794 100%);
}

.btn-grad_home_red {
    text-align: center;
    text-transform: capitalize;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #eee;
    display: block;
    background-image: linear-gradient(to right, #961600 0%, #961600 51%, #961600 100%);
}

.btn-grad:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}

.flashing-button-blue {
    animation: blink 0.3s linear infinite;
    color: white;
    background: linear-gradient(to right, #457fca 0%, #5691c8 51%, #457fca 100%);
}

.flashing-button-red {
    animation: blink 0.5s linear infinite;
    color: white;
    background: linear-gradient(to right, #961600 0%, #961600 51%, #961600 100%)
}

.flashing-button-green {
    animation: blink 0.7s linear infinite;
    color: white;
    background: linear-gradient(to right, #62cf22 0%, #3cbd30 51%, #187c28 100%);
}

.flashing-button-orange {
    animation: blink 0.3s linear infinite;
    color: white;
    background: linear-gradient(to right, #d8970b 0%, #be9409 51%, #ad8005 100%);
}

.flashing-button-purple {
    animation: blink 0.6s linear infinite;
    color: white;
    background: linear-gradient(to right, #795794 0%, #795794 51%, #795794 100%);
}

@keyframes blink {
    50% {
        background: yellow;
        color: black;
    }
}

.headerTxt {
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 5px #eee;
}

.JCheader {
    text-align: center;
    color: rgb(185, 26, 26);
}

.JCbody {
    text-align: justify;
    color: rgb(185, 26, 26);
    margin-bottom: 10px;
}

ul {
    padding: 0;
    list-style-type: none;
}