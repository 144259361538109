body {
    background: #394d7b;
    font-size: 14px;
    text-align: justify;
  }

.auditorErrorsTable {
    border-radius: 1px;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #536380;
    padding: 20px;
    position: relative;
    font-family: "Montserrat", sans-serif;
  }

.auditorErrorsTableDropdown {
  border-radius: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #536380;
  padding: 20px;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.uploadButtons {
  padding: 5px;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.fileUploader {
  padding: 15px;
  align-content: center;
  position: relative;
  font-family: "Montserrat", sans-serif;
}