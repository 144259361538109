* {
  padding: 0;
  margin: 0;
}

.container {
  height: auto;
  width: auto;
}

.details {
  height: 90%;
  margin-bottom: 5%;
  overflow: hidden;
  position: relative;
}

.details img {
  max-width: 100%;
  padding-right: 5%;
  overflow: hidden;
}

.image-container{
  display: flex;
  justify-content: center;
}

::-webkit-scrollbar {
  display: none;
}