.header-dark {
  height: 100px;
  background-color: #040f19;
}

.header-dark h1 {
  padding: 20px;
  color: aliceblue;
}

@font-face {
  font-family: "DaggerSquare";
  src: local("DAGGERSQUARE"), url(../fonts/DAGGERSQUARE.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.header-navbar {
  background-color: #040f19;
  padding: 0px 10px;
  font-family: "DaggerSquare", "Courier New", Courier, monospace;
}

.name {
  /* padding: 2px; */
  margin-left: 10px;
  margin-top: 10px;
  font-size: x-large;
  cursor: pointer;
}
