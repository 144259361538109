@font-face {
  font-family: "DaggerSquare";
  src: local("DAGGERSQUARE"), url(../fonts/DAGGERSQUARE.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.thefont {
  font-family: "DaggerSquare", "Courier New", Courier, monospace;
}

.footer-dark {
  padding: 50px 0;
  color: #f0f9ff;
  background-color: #040f19;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item.text {
  margin-bottom: 36px;
}

@media (max-width: 767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width: 991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 35px;
  display: inline-block;
  text-align: center;
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}

.footer-dark .item.social > a:hover {
  opacity: 1;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 1;
  font-size: 13px;
  margin-bottom: 0;
}

.nav-pills .nav-item {
  color: #FFF;
  font-weight: 500;
}

.nav-pills .nav-item:hover {
  background-color: #bbcfed;
  color: #FFF;

}


 .nav-pills .nav-item.active  {
  background-color: #394d7b;
  color: #FFF;
}
