.header_margin_Pervious {
  margin: 2px 5px;
  padding: 1px 2px;
}

.p_node_name_header {
  padding: 1px;
  width: 147px;
}

.p_node_type_header {
  padding: 1px;
  width: 162px;
}

.p_node_area_header {
  padding: 1px;
  width: 92px;
}

.p_node_STORM {
  padding: 1px;
  width: 10px;
}
.p_node_STORM_tp {
  padding: 1px;
  width: 10px;
}
.p_node_STORM_tn {
  padding: 1px;
  width: 10px;
}

.p_node_STORM_fr {
  padding: 1px;
  width: 140px;
}

.instaResultSpacer_pervious {
  width: 20px;
}

.p_node_ResultSpray {
  padding: 1px;
  width: 170px;
}

.resultSpray_margins {
  margin: 2px 0px;
  padding: 1px 2px;
}

.alert_set_pervious {
  padding: 0.2rem 1.25rem;
  margin-bottom: 0.3rem;
}

.table_line_pervious {
  border-top: 1px solid rgb(149, 186, 209);
}

.tooltip_zindex {
  z-index: 100;
}

#bootstrap-dropdown{
  margin: 2px 5px;
  padding: 3px;
  width: 150px;
  height: 26px;
  font-size: 14px;
  text-align: left;
  z-index: 10
}

.dropright{
  inset: unset !important;
  transform: unset !important;
}