body {
  background: #394d7b;
  font-size: 14px;
  text-align: justify;
}

#noanim-tab-example-tab-Swinburne{
  border-right: groove;
  border-left: groove;
}

.ad_box_{
  
  flex-direction: row;
  flex: 80%;
  /* margin-left: 20px; */
  /* margin: 20px; */
  margin-top: 1%;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  min-width: 300px;
  border-width: 3px;
  border-radius: 10px;
  /* min-height: 336px; */
  /* max-height: 336px; */
  background-color: #cedcf8;
  border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
  padding: 1%;
}
.buttonClass{
  background: none;
  border: none;
  padding: 0;
  outline: inherit;
  margin: 1%;
}
.osdsAdImg {
  /* height: 40%; */
  max-height: 37px;
  
  
}
.logoDiv{
  margin: 0.05%;
  /* height: 40%; */
}
.spanclass{
  font-family: "Montserrat", sans-serif;
  margin: 0.05%;
  font-size: 1.25rem;
  color: rgb(4, 38, 148);
  
}
.osds_ads{
  
 
  background: #eaf1ff;
  border-radius: 10px;
  border-width: 3px;
  border-style: solid;
  border-color: #536380;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 20px;
  position: relative;
  font-family: "Montserrat", sans-serif;

}
.panOuterCalc {
  background: #bbcfed;

  border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;

  border-top-right-radius: 100px;
  -o-border-top-radius: 200px;
  -ms-border-top-radius: 200px;
  -moz-border-top-radius: 200px;
  -webkit-border-top-radius: 200px;

  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  margin: 10px 0;
  padding: 10px 30px;
  position: relative;
}

.panInnerCalc {
  background: #eaf1ff;
  border-radius: 10px;
  border-width: 3px;
  border-style: solid;
  border-color: #536380;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 20px;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.panNodeWQ {
  background: #cedcf8;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.panNodeResult {
  background: hsl(220, 53%, 90%);
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.panNode {
  background: #eaf1ff;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
  z-index: 10;
}

.panNode_pervious {
  background: #eaf1ff;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
  max-width: 707px;
}

.panNodePreDev {
  background: #fff0ba;
  border-radius: 5px;
  border-color: #d9c16a;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
  z-index: 4;
}

.panNode_perviousPreDev {
  background: #fff0ba;
  border-radius: 5px;
  border-color: #d9c16a;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
  max-width: 707px;
}

.panNodePostDev {
  background: #b3e3b5;
  border-radius: 5px;
  border-color: #6cb86f;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
  z-index: 10;
}

.panNode_perviousPostDev {
  background: #b3e3b5;
  border-radius: 5px;
  border-color: #6cb86f;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
  max-width: 707px;
}

.panNode_data {
  background: #eaf1ff;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.panNode_dataPreDev {
  background: #fff0ba;
  border-radius: 5px;
  border-color: #d9c16a;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.panNode_dataPostDev {
  background: #b3e3b5;
  border-radius: 5px;
  border-color: #6cb86f;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.panNodeExisting {
  background: #f1dc92;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.panNodeDeveloped {
  background: #9cdb9e;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 10px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.panNode_osd {

  border-style: solid;
  margin-top: 10px;
  margin-bottom: 20px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 20px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.spacer {
  width: 70px;
}

.progressBarDiv {
  margin-top: 10px;
}

.small_spacer {
  width: 15px;
}

.lableFormatterOSD {
  margin: 2px 5px;
  width: 80px;
  text-align: right;
}

.lableFormatterAEP {
  margin: 2px 5px;
  width: 80px;
  text-align: right;
}

input,
select {
  width: 200px;
  margin: 2px 5px;
}

.calculate_button {
  margin-top: 20px;
}

.tableChoice {
  margin-bottom: 5px;
}

select {
  padding: 3px;
}

.checkbox {
  width: auto;
  margin-right: 5px;
  margin-top: 5px;
}

.checkboxPad {
  padding: 10px;
  margin: 0;
}

.checkboxPad_hasWQ {
  padding: 10px;
  margin: 0;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  background-color: #cedcf8;
}

.checkboxPad_hasOsd {
  padding: 10px;
  margin: 0;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  background-color: #ded5f5;
}

.checkboxLabel {
  margin-bottom: 0;
}

.checkboxLabel_Red {
  margin-bottom: 0;
  color: red;
}

.input_small {
  width: 50px;
}

.eolPad {
  margin-left: 34%;
  margin-top: -3.5%;
}

.input_eol_size {
  width: 130px;
}

.input_siteArea {
  width: 175px;
}

.input_suburb {
  width: 134px;
}

.input_area {
  width: 80px;
}

.select_area {
  width: 120px;
}

.imp_node_name {
  width: 185px;
}

.select_area_OSD {
  width: 150px;
}

.input_area_pervious {
  width: 70px;
}

.select_area_pervious {
  width: 135px;
}

.flex-horizontal {
  display: flex;
}

.item-left {
  flex: 1000%;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #457fca 0%,
    #5691c8 51%,
    #457fca 100%
  );
}
.btn-grad {
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.headerTxt {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 5px #eee;
}

.header {
  background-image: "../storm/assets/img/header.png";
  height: 200px;
}

input {
  filter: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;

  transition: background-color 5000s ease-in-out 0s;
  border: 1px solid grey;
}

.perviousSection{
  z-index: 1;
}
.imperviousSection{
  z-index: 3;
}

body {
  font-size: 13px;
}


.swinburne_vip_osd {
  background: #ded5f5;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 0px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 20px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.rational_vip_osd {
  background: #fff200;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 0px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 20px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}
.boyds_vip_osd {
  background: #cedcf8;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 0px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 20px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

/*.spacer {*/
/*  width: 40px;*/
/*}*/

/*.small_spacer {*/
/*  width: 15px;*/
/*}*/

/*.lableFormatterOSD {*/
/*  margin: 2px 5px;*/
/*  width: 80px;*/
/*  text-align: right;*/
/*}*/

/*.lableFormatterAEP {*/
/*  margin: 2px 5px;*/
/*  width: 80px;*/
/*  text-align: right;*/
/*}*/

/*.checkboxPadOSD {*/
/*  padding: 0px;*/
/*  padding-left: 10px;*/
/*  !* padding-left: 0; *!*/
/*  margin: 0;*/
/*}*/

/*.checkboxOSDPad {*/
/*  padding: 10px;*/
/*  padding-left: 0;*/
/*  margin: 0;*/
/*}*/

/*.select_area_OSD {*/
/*  width: 120px;*/
/*}*/
