.innerBox {
    display: grid;
    background-color: #c3d5f0;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    /* border-style: solid; */
    padding: 10px;
    font-family: "Montserrat", sans-serif;
}
.thWordWrap{
    word-break: normal;
    max-width: 2000px;
    /* padding-right: 15px; */
}
.outerBox {
    background-color: #ded5f5;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    font-family: "Montserrat", sans-serif;
}

.innerBoxGreen {
    display: grid;
    background-color: #a9d1a5;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    /* border-style: solid; */
    padding: 10px;
    font-family: "Montserrat", sans-serif;
}

.innerBoxYellow {
    display: grid;
    background-color: #ffd9a8;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    /* border-style: solid; */
    padding: 10px;
    font-family: "Montserrat", sans-serif;
}

.input_area {
    width: 120px;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}