* {
	box-sizing: border-box;
}

body {
	background: #394d7b; 
	/* font-size: 16px; */
	text-align: justify;
}

/* * {
    box-sizing: border-box;
  }

body {
    background: #394d7b; 
    font-size: 15px;
    text-align: justify;
}

.panOuter {
    display: flex;
    flex-direction: column;

    background: #BBCFED;

	border-radius: 10px;
	-o-border-radius: 10px;
	-ms-border-radius: 10px;
	-moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    
    border-top-right-radius: 100px;
    -o-border-top-radius: 200px;
	-ms-border-top-radius: 200px;
	-moz-border-top-radius: 200px;
    -webkit-border-top-radius: 200px;

	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
	-o-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
	-ms-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    margin: 1% 10%;
    padding: 20px 30px;
	position: relative;
}

.item{
    padding: 10px;
    position: inherit;
}

.item h6 {
    font-weight:bold;
}

.flex-horizontal    {
    display: flex;
    flex-direction: row;
    position: relative;
}

@media screen and (max-width: 500px)    {
    .flex-horizontal    {
        flex-direction: column;
    }
}

.panInner {
    flex: 30%;
    display: flex;
    flex-direction: column;

    background: #EAF1FF;
    border-radius: 10px;
    border-width: 3px;
    border-style: solid;
    border-color: #536380;
	-o-border-radius: 10px;
	-ms-border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
    padding: 20px;
	position: relative;
	font-family: 'Montserrat', sans-serif;
    min-width: 300px;
    min-height: 300px;
}

.inputEmail {
    margin-left: 0px;
}

.aboutStorm {
    display: flex;
    flex-direction: column;
    flex: 80%;
    background: #BBCFED;
    padding: 20px;
	position: relative;
	font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    min-width: 300px;
} */

.panNode {
    background: #EAF1FF;
    border-radius: 5px;
    border-color: #7290CF;
    border-width: 3px;
    border-style: solid;
	-o-border-radius: 10px;
	-ms-border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
    padding: 20px;
	position: relative;
	font-family: 'Montserrat', sans-serif;
}

input, select {
    width: 200px;
    margin: 2px 5px;
}

select {
    padding: 3px;
}

.input_small {
    width: 50px;
}

.input_siteArea {
    width: 175px;
}

.input_suburb {
    width: 134px;
}

.input_area {
    width: 80px;
}

.select_area {
    width: 150px;
}






.btn-grad {background-image: linear-gradient(to right, #457fca 0%, #5691c8  51%, #457fca  100%)}
.btn-grad {
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   display: block;
 }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 .headerTxt {
     font-size:50px;
     font-weight: bold;
     color: #fff;
     text-shadow: 0 0 5px #eee;
 }


  

  .header {
      background-image: "./assets/img/header.png";

      height: 200px;
  }


  .stormRating {
      padding: 50px;
  }
 
  .exprotPDF {
    padding: 50px;
  }


  
  .btn-grad_export {background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%)}



  input {
    filter: none;
  }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  
  -webkit-text-fill-color:black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;

  transition: background-color 5000s ease-in-out 0s;
  border: 1px solid grey;
}
 
  