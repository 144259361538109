body {
  font-size: 13px;
}

.aboveGroundButton {
  /* box-shadow: 0px 0px 0px 2px #9fb4f2; */
  background: linear-gradient(to bottom, #eef700 5%, #a16b0d 100%);
  /* background-color: #a5aa07; */
  /* border-radius: 30px; */
  /* border: 1px solid #4e6096; */
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  /* font-size: 14px; */
  /* padding: 8px 5px; */
  /* text-decoration: none; */
  text-shadow: 0px 1px 0px #283966;

}
.aboveGroundButton:hover {
	background:linear-gradient(to bottom, #a16b0d 5%, #f7ff00 100%);
	background-color:#a16b0d;
}
.aboveGroundButton:active {
	position:relative;
	top:1px;
}

.belowGroundPipeButton {
  /* box-shadow: 0px 0px 0px 2px #9fb4f2; */
  background: linear-gradient(to bottom, #00f752 5%, #067a0c 100%);
  /* background-color: #a5aa07; */
  /* border-radius: 30px; */
  /* border: 1px solid #4e6096; */
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  /* font-size: 14px; */
  /* padding: 8px 5px; */
  /* text-decoration: none; */
  text-shadow: 0px 1px 0px #283966;

}
.belowGroundPipeButton:hover {
	background:linear-gradient(to bottom, #067a0c 5%, #00f752 100%);
	background-color:#0a8b0a;
}
.belowGroundPipeButton:active {
	position:relative;
	top:1px;
}

.belowGroundtankButton {
  /* box-shadow: 0px 0px 0px 2px #9fb4f2; */
  background: linear-gradient(to bottom, #00b7ff 5%, #084799 100%);
  /* background-color: #a5aa07; */
  /* border-radius: 30px; */
  /* border: 1px solid #4e6096; */
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  /* font-size: 14px; */
  /* padding: 8px 5px; */
  /* text-decoration: none; */
  text-shadow: 0px 1px 0px #283966;

}
.belowGroundtankButton:hover {
	background:linear-gradient(to bottom, #084799 5%, #00b7ff 100%);
	background-color:#083a85;
}
.belowGroundtankButton:active {
	position:relative;
	top:1px;
}

        

.panNode_osd {
  background: #ded5f5;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 0px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 20px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.panNode_osd_NorBE {
  background: #ded5f5;
  border-radius: 5px;
  border-color: #7290cf;
  border-width: 3px;
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 0px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 20px;
  position: sticky;
  font-family: "Montserrat", sans-serif;
}

.spacer {
  width: 40px;
}

.small_spacer {
  width: 15px;
}

.lableFormatterOSD {
  margin: 2px 5px;
  width: 80px;
  text-align: right;
}

.lableFormatterAEP {
  margin: 2px 5px;
  width: 80px;
  text-align: right;
}

.checkboxPadOSD {
  padding: 0px;
  padding-left: 10px;
  /* padding-left: 0; */
  margin: 0;
}

.checkboxOSDPad {
  padding: 10px;
  padding-left: 0;
  margin: 0;
}

.select_area_OSD {
  width: 120px;
}