.stormRating {
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.stormRatingMainWQ {
  padding: 5px;
  border-radius: 5px;
}

.stormRatingMainOSD {
  margin-top: 10px;
  padding: 5px;
  background-color: rgb(236, 248, 229);
  border-radius: 5px;
}

.exprotPDF {
  padding: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.btn-result {
  margin: 4px;
}
