.addAddressLink {
  background-color: #eaf1ff;
  text-align: left;
  padding: 0px 0px;
  /* padding: 0px 20px; */
}

.addAddressLinkTabHeader {
  background-color: #eaf1ff;
  text-align: left;
  padding: 0px 20px;
  /* padding: 0px 20px; */
}

.addAddressLinkButton {
  margin: 0;
  padding-left: 0;
}

.accordionSpacing {
  margin-bottom: 20px;
}
