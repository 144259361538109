/*Css for the div containing ad logos*/
.AdsImageDiv{
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 4%;
    max-width: 300px;
    overflow: hidden;
    text-align: center;
  }
  /*Css for the logo images of ads*/
  .AdsImageClass{
    max-width: 95%;
    max-height: 95%;
  }