.central {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacer {
  margin-left: 5px;
  margin-right: 5px;
}

.col-centered{
  margin-left: 100;
  /* margin: 0 auto; */
}