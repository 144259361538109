h3 {
  position: absolute;
  left: 2rem;
  top: 2rem;
  color: #040f19;
  z-index: 10;
  margin: 0;
  padding: 0;
}

.search {
  /* display: flex; */
  position: absolute;
  top: 15%;
  text-align: center;
  /* transform: translateX(0%); */
  width: 1000px;
  max-width: 1000px;
  min-width: 500px;
  z-index: 1;
  padding: 5px;
  box-shadow: 1px;
}

/* for Map cms filter */
.selector {
  margin-top: 100px;
  margin-bottom: 20px;
  margin-left: 10%;
  width: 80%;
}

.icon-click-prompt {
  margin-top: 15px;
  text-align: center;
  background-color: aquamarine;
}

.err-msg {
  color: red;
}
/* end for Map cms filter */

.the-Map-Div {
  margin: 15px 5px 5px 5px;
}

.comboboxWidth {
  width: 100%;
  min-width: 560px;
  max-width: 650px;
  height: 100%;
  margin: 0;
  margin-left: 2px;
  /* background-color: aliceblue; */
}
